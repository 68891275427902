<template>
	<div>
		<v-dialog v-model="showEdit" fullscreen persistent v-if="showEdit">
			<v-card>
				<v-toolbar
					dense
					color="primary"
					height="40"
					dark
					label="Nueva Programación">
					<h3>Programación de Cosecha</h3>
					<v-spacer></v-spacer>
					<div v-if="item">
						<v-btn
							v-if="item.HvsStatus == 1"
							fab
							class="elevation-0 mr-3"
							small
							color="primary"
							dark
							@click="$refs.edit.save()"
							><v-icon>mdi-content-save</v-icon></v-btn
						>
					</div>
					<v-btn
						v-else
						fab
						class="elevation-0 mr-3"
						small
						color="primary"
						dark
						@click="$refs.edit.save()"
						><v-icon>mdi-content-save</v-icon></v-btn
					>
					<v-btn
						fab
						class="elevation-0"
						small
						color="primary"
						dark
						@click="showEdit = false"
					>
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-toolbar>
				<v-container class="mt-2">
					<HarvestScheduleEdit
						ref="edit"
						:objEdit="item"
						@close="closeEdit()"
					/>
				</v-container>
			</v-card>
		</v-dialog>

		<v-container>
			<v-row>
				<v-col lg="12" class="pt-2 pb-1">
					<s-crud
						@add="(item = null), (showEdit = true)"
						edit
						add-no-function
						add
						height="auto"
						searchInput
						ref="crud"
						@getFullData="getData($event)"
						@edit="editHvs($event)"
						title="Programación de Cosecha"
						:remove="selectedOne.HvsState==0"
						@rowSelected="rowSelected($event)"
						:config="config"
						@save="save($event)"
						:filter="filterSchedule"
						no-full
						:show-group-by="true"
						:group-by="'VehPlate'"
					>
					<!-- :single-row="false" -->
						<template v-slot:filter>
							<v-container>
								<v-row justify="center" class="s-col-form">
									<v-col lg="3"
										><s-date
											label="Fecha Inicio"
											v-model="filterSchedule.HvsBeginDate"
										></s-date>
									</v-col>
									<v-col lg="3"
										><s-date
											label="Fecha Fin"
											v-model="filterSchedule.HvsEndDate"
										></s-date>
									</v-col>
									<v-col lg="3"
										><s-select-definition
											label="Cultivo"
											:def="1173"
											v-model="filterSchedule.TypeCultive"
											clearable
										/>
									</v-col>
									<v-col lg="3"
										><s-select-definition
											label="Tipo Cultivo"
											:def="1172"
											v-model="filterSchedule.TypeCrop"
											clearable
										/>
									</v-col>
								</v-row>
								<v-row justify="center" class="s-col-form">
									<v-col  lg="3" >
										<s-select-foreman
											autocomplete
											full
											clearable
											label="Capataz"
											v-model="filterSchedule.FrmID"
											
										></s-select-foreman>
									</v-col>
									<v-col lg="3">
										<s-select-supervice
											clearable
											label="Supervisor de Cosecha"
											v-model="filterSchedule.NtpSuperviceID"
											full
										></s-select-supervice>
      						</v-col>
									<v-col lg="3">
										<s-select-supervice
											clearable
											label="Supervisor de fundo"
											v-model="filterSchedule.NtpSuperviceID2"
											full
										></s-select-supervice>
      						</v-col>
									<v-col lg="3"
										><s-select-definition
											label="Destino"
											:def="1175"
											v-model="filterSchedule.TypeDestination"
											clearable
										/>
									</v-col>

								</v-row>
							</v-container>
						</template>

						<template v-slot:ViewCert="{ row }">
							<v-btn x-small color="info" block outlined @click="clickView(row)">
								<v-icon style="font-size: 16px !important">fas fa-eye</v-icon>
							</v-btn>
						</template>

						<template v-slot:HvsStatusText="{ row }">
							<v-chip
								x-small
								:color="row.HvsStatus == 1 ? 'warning' : 'success'"
								>{{ row.HvsStatusText }}</v-chip
							>
						</template>

						<template v-slot:HvsState="{ row }">
							<v-chip
								x-small
								:color="row.HvsState == 1 ? 'info' : 'error'"
								>{{
									row.HvsState == 1
										? "Recepcionado"
										: "Sin Recepcionar"
								}}</v-chip
							>
						</template>

						<template v-slot:options>
							<v-tooltip bottom="">
								<template v-slot:activator="{ on }">
									<v-btn
									v-on="on"
									text
									black 
									@click="createHarvestSchedule()"
									small
									>
									<i class="fas fa-layer-group"></i>
									</v-btn>
								</template>
								<span>Crear</span>
							</v-tooltip>

							<v-tooltip bottom="" v-if="selectedHarvest.length > 0">
								<template v-slot:activator="{ on }">
									<v-btn
									v-on="on"
									text
									black 
									@click="openDialogGuide()"
									small
									>
									<i class="mdi mdi-note-text"></i>
									</v-btn>
								</template>
								<span>Guía Remisión</span>
							</v-tooltip>
						</template>

						<template v-slot:accion="{ row }">
							<v-checkbox
								v-model="selectedHarvestCheck"
								v-if="row.HvsStatus == 1"
								:value="row.HvsID"
								@click="addHarvestScheduleToCreate()"
								hide-details
								style="margin-top: -5px"
							>
								
							</v-checkbox>
						</template>
						
						<!-- guia de remision -->
						<template v-slot:guidecheck="{ row }">
							<v-checkbox
								v-model="selectedGuideCheck"
								:value="row.HvsID"
								@click="addHarvestScheduleToCreate()"
								hide-details
								style="margin-top: -5px"
							>
								
							</v-checkbox>
						</template>
					</s-crud>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<v-card>
						<v-container>
							<h2 style="display:inline;">Cantidad Jabas: </h2> <h2 style="display:inline;color:blue"> {{ this.totalDataRegist }}</h2>
						</v-container>
					</v-card>
				</v-col>
			</v-row>
		</v-container>

		<!-- guias de remision previo-->
		<v-dialog
			v-if="dialogGuide"
			v-model="dialogGuide"
			persistent
			width="250"
		>
			<s-toolbar
				label="Seleccione Guía"
				close
				@close="dialogGuide = false"
			></s-toolbar>
			<v-card>
				<v-container class="text-center">
					<v-col>
						<v-row align="center">
							<v-col class="s-col-form">
								<v-btn
									x-small
									color="primary"
									@click="openDialogGuideSelected(4)"
								>
									Guía de Fruta
								</v-btn>
							</v-col>
						</v-row>
						<v-row align="center">
							<v-col class="s-col-form">
								<v-btn
									x-small
									color="success"
									@click="openDialogGuideSelected(3)"
								>
									Guía de Material
								</v-btn>
							</v-col>
						</v-row>
					</v-col>
				</v-container>
			</v-card>
		</v-dialog>

		<!-- guia de remiision seleccionada -->
		<v-dialog
			v-if="dialogGuideSelected"
			v-model="dialogGuideSelected"
		>
			<!-- <s-toolbar
				label="Guía de Remisión"
				close
				@close="dialogGuideSelected = false"
			></s-toolbar> -->
			<!-- <fruit-guide
				v-if="guideSelected == 1"
				:HvsID="HvsID"
			></fruit-guide>
			<material-guide
				v-if="guideSelected == 2"
				:HvsID="HvsID"
			></material-guide> -->

			 
			<lgs-referral-guide-edit
				:typeGuide="typeGuide"
				@closeDialogReferral="closeDialogReferral()"
				:referralGuideSelected="referralGuideSelected"
			></lgs-referral-guide-edit>
		</v-dialog>

		<v-dialog
			v-if="openDialogView"
			v-model="openDialogView"
			persistent
			style="display: contents"
			width="70%"
			>
			<s-certification-lot :FltID="FltID" @close="closeCert()" />
		</v-dialog>
	</div>
</template>
<script>
	import _sFarm from "@/services/Technicalassistance/AgriculturalFarmsService";
	import _sHarvest from "@/services/Technicalassistance/HarvestSchedule";
	import _sHarvestGuide from "@/services/Technicalassistance/HarvestScheduleGuideService";
	 
	import _sParameter from "@/services/General/ParameterService.js";

	import SCertificationLot from "@/components/TecnicalAssistence/sCertificationLot.vue";
	import TableMaterial from "@/components/HarvestSchedule/TableMaterial.vue";
	import TableCarrier from "@/components/HarvestSchedule/TableCarrier.vue";
	import TableForeman from "@/components/HarvestSchedule/TableForeman.vue";
	import SSelectForeman from "@/components/HarvestSchedule/SSelectForeman";
	import SSelectSupervice from "@/components/HarvestSchedule/SSelectSupervice";
	import _sAlertHistoryService from "@/services/FreshProduction/AlertHistoryService";
	import HarvestScheduleEdit from "@/views/TechnicalAssistance/HarvestSchedule/HarvestScheduleEdit.vue";
	import FruitGuide from './GuideReferral/FruitGuide.vue';
	import MaterialGuide from './GuideReferral/MaterialGuide.vue';
	import LgsReferralGuideEdit from '@/views/Logistics/ReferralGuide/LgsReferralGuideEdit.vue';


	export default {
		components: {
			TableMaterial,
			TableCarrier,
			SSelectForeman,
			TableForeman,
			SSelectSupervice,
			HarvestScheduleEdit,
			SCertificationLot,
			FruitGuide,
			MaterialGuide,
			LgsReferralGuideEdit,
		},
		props: {},
		data: () => ({
			selectedGuideCheck: [],
			typeGuide: 0,
			referralGuideSelected: {},
			dialogGuide : false,
			dialogGuideSelected : false,
			guideSelected : 0,
			openDialogView: false,
			totalDataRegist : 0,
			selectedHarvestCheck : [],
			selectedHarvest: [],
			showEdit: false,
			item: null,
			FagIDg: 0,
			ZonDescription: "",
			SzoDescription: "",
			min: 0,
			FagAddress: "",
			VrtDescription: "",
			listTableMaterial: [], //carlos
			TypeCultive: 0, //carlos
			validateMaterial: false, //carlos
			TypeLoad: 0, //carlos
			FagName: "", //carlos
			TypeCrop: 0, //carlos
			TypeCultiveText: "",
			TypeCropText: "",
			itemsForeman: [],
			dataByCodSenasa: [],
			dataTableTransport: [],
			ObjAgriculturalFarm: [],
			HvsID: 0,
			HvsStatus: 0,
			typeCharge: 0,
			itemsFarm: [],
			itemsLots: [],
			searchSenasaActive: false,
			itemsTransport: [],
			ObjFundo: [],
			objFltID: null,
			itemsCapataz: [],
			ObjSupervice: null,
			ObjProducer: { PrdID: -1 },
			itemsMaterial: [],
			isEdit: false,
			forSearch: true,
			filterSchedule: { BeginDate: null, EndDate: null },
			selectedOne: {},
			config: {
				model: {
					HvsID: "ID",
					HvsDate: "date",
					HvsStatusText: "",
					HvsStatus: "",
					HvsState: "",
					accion: "",
					ViewCert: "",
					guidecheck: ""
				},
				service: _sHarvest,
				headers: [
					{ text: "Guía R", value: "guidecheck", width: 70},
					{ text: "View", value: "ViewCert", width: 70 },
					{ text: "Accion", value: "accion", width: 10 },
					{ text: "ID", value: "HvsID", width: 10 },
					// { text: "Estado", value: "HvsStatus", width: 10 },
					{ text: "Estado", value: "HvsStatusText", width: 10 },
					{ text: "Fecha", value: "HvsDate" },
					{ text: "Cod.Senasa", value: "FagCodeSenasa", width: 100 },
					{ text: "Fundo", value: "FagName", width: 280 },
					{ text: "Lote", value: "FltName", width: 80 },
					{ text: "Cantidad Jaba", value: "AmountTotalJaba" },
					{ text: "Productor", value: "PrdText", width: 450 },
					{ text: "Tipo Servicio", value: "TypeServiceText" },
					{ text: "Procedencia", value: "TypeOriginText", width: 150 },
					{ text: "Cultivo", value: "TypeCultiveText" },
					{ text: "Variedad", value: "VrtDescription" },
					{ text: "Tipo Cultivo", value: "TypeCropText" },
					{ text: "Destino", value: "TypeDestinationText", width: 100 },
					{ text: "Planta Procesadora", value: "TypeProcessingPlantText" },
					{ text: "Empacadora", value: "TypeBalerText" },
					{ text: "Placa Fruta", value: "VehPlate" },
					{ text: "Placa Materiales", value: "VehPlateMaterials" },
					{ text: "", value: "HvsState" },
					/* { text: "Productor", value: "PrdText", width: 250 }, */

					/* { text: "Lote", value: "FltName", width: 250 }, */
				],
			},
			HvsID : 0,
		}),
		methods: {
			closeDialogReferral(){
				this.dialogGuideSelected = false;
			},
			openDialogGuide()
			{
				if(this.selectedHarvest.length > 0)
				{
					this.dialogGuide = true;
				}
			},

			openDialogGuideSelected(value)
			{
				this.guideSelected = value;
				this.dialogGuide = false;

				if(this.selectedGuideCheck.length > 1 && value == 4)
				{
					this.$fun.alert("Debe de seleccionar solo un registro", "warning");
					return;
				}

 				//samir
				this.typeGuide = value;
				let detail = [];
				let counter = 0;
				//fruta
				if (value == 4) {

					let objParam = {
						PrmName: "MOTIVOGUIAFRUTA"
					};
						
					_sParameter
					.search(objParam, this.$fun.getUserID())
					.then((r) => {
						this.referralGuideSelected.RegOtherMotive = r.data.PrmValue;
						
					});
					counter++;
					detail.push({
						RgdLine: counter,
						DocEntry: 0,
						RgdDescription: this.selectedOne.TypeCultiveText + " " + this.selectedOne.TypeCropText + " Variedad: " 
						+ this.selectedOne.VrtDescription.toUpperCase() 
						+ " Proveedor: " + this.selectedOne.PrdText
						+ " ID: " + this.selectedOne.FltID,
						RgdQuantity: this.selectedOne.HvsQuantity,
						RgdUnitMeasurement: "JABA",
						SecStatus: 1,	
						UsrCreateID: this.$fun.getUserID(),
						TypeDetail: 1
					});			 
				}
				//materiales
				if (value == 3) {

					let objParam = {
						PrmName: "MOTIVOMATERIALES"
					};
						
					_sParameter
					.search(objParam, this.$fun.getUserID())
					.then((r) => {
						this.referralGuideSelected.RegOtherMotive = r.data.PrmValue;
						
					});
					
					let stringHvsID = this.selectedGuideCheck.join();

					//load material
					_sHarvestGuide.materialreferralguide(stringHvsID, this.$fun.getUserID())
						.then((r) => {
							if (r.status == 200) {
								console.log("lista de materiales", r.data)
								r.data.details.forEach(element => {		
									counter++;						
									detail.push({
										RgdLine: counter,
										DocEntry: 0,
										RgdDescription: element.MtrMaterial,
										RgdQuantity: element.MtrQuantity,
										RgdUnitMeasurement: "UNIDAD",
										SecStatus: 1,	
										UsrCreateID: this.$fun.getUserID(),
										TypeDetail: 1
									});
								});
							}
						});
				}
				counter = 0;
				console.log("guia seleccionadoa ", this.selectedOne);
				this.referralGuideSelected.RegAddressStartPoint = this.selectedOne.FagAddress.trim();
				this.referralGuideSelected.PrsDocumentNumberDriver = this.selectedOne.PrsDocumentNumberDriver.trim();
				this.referralGuideSelected.NpdFullNameDriver = this.selectedOne.NpdFullNameDriver.trim();
				this.referralGuideSelected.NpdDocumentNumberDriver = this.selectedOne.NpdDocumentNumberDriver;
				this.referralGuideSelected.VehPlate = this.selectedOne.VehPlate.trim();
				this.referralGuideSelected.TypeAreGuide = 2;
				this.referralGuideSelected.DocEntry = this.selectedOne.HvsID;

				
				

				this.referralGuideSelected.referralGuideItems = detail;

				this.dialogGuideSelected = true;
			},

			clickView(val) {
				this.FltID = val.FltID;
				this.openDialogView = true;
			},

			closeCert() {
				this.openDialogView = false;
			},

			getData(item){
				this.totalDataRegist = 0;
				item.data.forEach((x)=>{
					this.totalDataRegist += x.AmountTotalJaba; 
				});

			},
			addHarvestScheduleToCreate(item){
				console.log('arr ', this.selectedHarvestCheck);
			},

			async createHarvestSchedule()
			{
				this.$fun.alert("¿Seguro de crear?", "question")
				.then(r => {
					if(r.value){
						let countRespo = 0;
						let countSelectedHarvest = this.selectedHarvest.length;
						this.selectedHarvestCheck.forEach(element => {
							_sHarvest
							.groupcreate(element, this.$fun.getUserID())	
							.then(resp => {
								if(resp.status == 200){
									console.log('entro');
									this.$fun.alert("Creadas correctamente", "success");
									this.$refs.crud.refresh();
									countRespo = countRespo + 1;	
								}
							})
						});
						//this.filterSchedule.BeginDate = new Date();
						
						/* console.log('countRespo ', countRespo);
						console.log('countSelectedHarvest ', countSelectedHarvest);
						if(countRespo == countSelectedHarvest){
							this.$fun.alert("Creadas correctamente", "success");
						}
						else{
							this.$fun.alert("Ocurrio un error al crear programaciones", "error");
						} */
					}
				})
			},

			editHvs(item) {
				this.showEdit = true;
				this.item = item;
			},
			closeEdit() {
				this.showEdit = false;
				this.$refs.crud.refresh();
			},
			change() {
				this.forSearch = false;
			},

			input(item) {
				item.FagID = this.ObjAgriculturalFarm.FagID;
				item.FagAddress = this.ObjAgriculturalFarm.FagAddress;
				this.FagName = item.FagName;
				if (this.ObjAgriculturalFarm.FagID != undefined) {
					this.FagIDg = this.ObjAgriculturalFarm.FagID;
				}
			},

			emitTableTransport(item) {
				this.dataTableTransport = item;
			},

			dataItemsForeman(dataItemsForeman) {
				this.itemsForeman = dataItemsForeman;
			},

			dblclick(item) {
				this.PrdID = item.PrdID;
				this.TypeCultiveText = item.TypeCultiveText;
				this.TypeCropText = item.TypeCropText;
				this.FagName = item.FagName;
				this.dataByCodSenasa = item;
				this.VrtDescription = item.VrtDescription;
				this.FagAddress = item.FagAddress;
				this.ZonDescription = item.ZonIDText;
				this.SzoDescription = item.SzoIDText;
				//this.forSearch = false;
			},

			InputTypeCharge(item) {
				this.TypeLoad = item;
				this.typeCharge = item;
			},
			//SELECCIONA FUNDOS
			inputFarm(FagID, item) {
				if (this.searchSenasaActive) return;
				if (FagID == null) {
					this.itemsLots = [];
					return;
				}
				_sHarvest.listLote(FagID, 0, "", this.$fun.getUserID()).then(r => {
					this.itemsLots = r.data;
					item.ZonID = this.itemsLots[0].ZonDescription;
					item.SzoID = this.itemsLots[0].SzoDescription;
					if (this.itemsLots.length > 0)
						if (item.FltID == 0 || item.FltID == null || item.FltID == "")
							this.objFltID = this.itemsLots[0];
						else {
							this.objFltID = this.itemsLots.find(
								x => x.FltID == item.FltID,
							);
						}
				});
			},
			//SELECCIONA FUNDOS

			//Programar Transporte
			rowSelected(item) {
				this.selectedHarvest = item;

				if(item !== null){
					if(item.length > 0){
						this.selectedOne = item[0]
						this.FltID = this.selectedOne.FltID;
						this.HvsID = item[0].HvsID;
						console.log('ssss ', this.FltID);
					}
					
				}	
				
			},

			inputTableListMaterial(listTableMaterial) {
				this.listTableMaterial = listTableMaterial;
			},
			save(item) {
				if (this.dataTableTransport.length == 0) {
					this.$fun.alert("Ingresar Transporte ", "warning");
					return;
				}
				this.itemsForeman.forEach(element => {
					if (!element.PrdID) {
						this.$fun.alert("Ingrese productor", "warning");
					}
				});

				item.TypeCrop = this.dataByCodSenasa.TypeCrop;
				item.VrtID = this.dataByCodSenasa.VrtID;
				item.TypeCultive = this.dataByCodSenasa.TypeCultive;
				item.ZonID = this.dataByCodSenasa.ZonID;
				item.SzoID = this.dataByCodSenasa.SzoID;
				item.GenSuperviceID = this.ObjSupervice.NtpSuperviceID;
				item.FagID = this.dataByCodSenasa.FagID;
				item.FagAddress = this.dataByCodSenasa.FagAddress;
				item.DetailsGenForeman = this.itemsForeman;
				item.DetailsTransport = this.dataTableTransport;


				console.log("cosecha a guardar samir", item);
				return;
				
				item.save();
			},

			assignMaterialCultive(item) {
				this.TypeCultive = item;
			},
		},
		created() {},
	};
</script>
