<template>
  <div>
    <v-card class="elevation-0">
      <v-row>
        <v-col cols="12" class="pb-0 pt-0">
          <h2>Transporte de Fruta</h2>
          <v-divider></v-divider>
          <!--	<s-toolbar
						remove
						:elevation="2"
						@removed="removeTransportista()"
						label="Transportistas"
					></s-toolbar>-->
        </v-col>
        <v-col lg="2" class="pt-3 pl-3 pr-6">
          <s-select-farm
            @dblclick="dblclick($event, TypeLoad)"
            v-model="VehPlate"
            :typeCharge="TypeLoad"
            :Quantity="Quantity"
          />
        </v-col>
        <v-col class="pt-3">
          <template>
            <v-data-table
              v-model="selectedTransporte"
              :headers="header"
              :items="itemsTransport"
              item-key="Line"
              hide-default-footer
              dense
              class="elevation-0"
            >
              <template v-slot:item.Actions="{ item }">
                <v-btn
                  block
                  color="error"
                  @click="removeTransportista(item)"
                  x-small
                >
                  <v-icon>mdi-close</v-icon></v-btn
                >
              </template>
              <template v-slot:item.TypeLoad="props">
                <s-select-definition
                  v-model="props.item.TypeLoad"
                  :def="1229"
                  disabled
                />
              </template>
            </v-data-table>
          </template>
        </v-col>
      </v-row>
      <v-row> </v-row>
    </v-card>
  </div>
</template>

<script>
import SSelectFarm from "@/components/HarvestSchedule/SSelectFarm";
import _sHarvest from "@/services/Technicalassistance/HarvestSchedule";

export default {
  props: {
    harvestSchedule: {
      type: Object,
      default: [],
    },

    Quantity: null,
  },
  components: {
    SSelectFarm,
  },
  data() {
    return {
      itemsDeleteTransport: [],
      header: [
        { text: "Placa", value: "TptEnrollment", width: 100, sortable: false },
        { text: "Vehículo", value: "TptVehicle", width: 120, sortable: false },
        { text: "Chofer", value: "TptDriver", sortable: false },
        { text: "Capacidad", value: "TptTon", width: 90, sortable: false },
        { text: "Carga", value: "TypeLoad", width: 120, sortable: false },
        { text: "Actions", value: "Actions", width: 100, sortable: false },
      ],
      selectedTransporte: [],
      itemsTransport: [],
      VehPlate: null,
      TypeLoad: 0,
      HvsID: 0,
    };
  },

  watch: {
    "harvestSchedule.TypeCharge"() {
      this.TypeLoad = this.harvestSchedule.TypeCharge;
      this.HvsID = this.harvestSchedule.HvsID;
      if (this.HvsID != 0) this.loadDataInitial();
    },

    "harvestSchedule.HvsID"() {
      this.HvsID = this.harvestSchedule.HvsID;
      this.loadDataInitial();
    },
  },

  methods: {
    clear() {
      this.itemsTransport = [];
    },
    dblclick(valor) {
      let objPlate = {
        TptEnrollment: valor.item.VehPlate,
        TptVehicle: valor.item.TypeVehicleText,
        TptDriver: valor.item.Driver,
        TptTon: valor.item.CfvCapacity,
        UsrCreateID: this.$fun.getUserID(),
        UsrUpdateID: this.$fun.getUserID(),
      };
      let ValidatePlate = false;
      if (this.HvsID > 0) objPlate.HvsID = item.HvsID;

      this.itemsTransport.forEach((element) => {
        if (element.TptEnrollment == valor.item.VehPlate) {
          ValidatePlate = true;
          this.$fun.alert("Placa ya Agregada", "warning");
        }
      });

      if (ValidatePlate == false) {
        this.itemsTransport.push(objPlate);
      }

      this.itemsTransport = this.itemsTransport;
      this.orderDetails();

      this.$emit("emitTableTransport", this.itemsTransport);
    },

    orderDetails() {
      let i = 0;
      this.itemsTransport.map((e) => {
        i++;
        e.Line = i;
      });
    },

    removeTransportista(item) {
      /* this.itemsTransport = this.itemsTransport.filter(
				(x) => x.TptEnrollment != element.TptEnrollment
			);

			if(element.TptId>0)
			{	this.itemsDeleteTransport.push(element);
					
				this.$emit('deleteItemTransport',this.itemsDeleteTransport);
			} */

      if (this.itemsTransport.length > 0) {
        if (item.TptId > 0) {
          this.itemsDeleteTransport.push(
            this.itemsTransport.filter((x) => x.Line == item.Line)[0]
          );
          this.itemsDeleteTransport.forEach((element) => {
            element.SecStatus = 0;
          });

          this.$emit("deleteItemTransport", this.itemsDeleteTransport);
        }

        this.itemsTransport = this.itemsTransport.filter(
          (x) => x.Line != item.Line
        );
        this.orderDetails();
      }

      this.$emit("emitTableTransport", this.itemsTransport);
      this.$emit("elementRemove", item);
    },

    loadDataInitial() {
      if (
        this.harvestSchedule.HvsID != 0 &&
        this.harvestSchedule.HvsID != null
      ) {
        //Llenar Transporte
        this.itemsTransport = [];
        _sHarvest
          .listProducerS(this.harvestSchedule.HvsID)
          .then((response) => {
            if (response.status == 200) {
              this.itemsTransport = [];
              let datat = response.data;
              datat.forEach((element) => {
                let obj = {
                  TptEnrollment: element.TptEnrollment,
                  TptVehicle: element.TptVehicle,
                  TptDriver: element.TptDriver,
                  TptTon: element.TptTon,
                  HvsID: element.HvsID,
                  TptId: element.TptId,
                  TypeLoad: element.TypeLoad,
                  SecStatus: 1,
                };
                this.itemsTransport.push(obj);
                this.orderDetails();
              });
              this.$emit("emitTableTransport", this.itemsTransport);
            }
          })
          .catch((err) => {});
      }
    },
  },

  created() {
    this.TypeLoad = this.harvestSchedule.TypeCharge;
    if (this.harvestSchedule.HvsID != 0) this.loadDataInitial();
  },
};
</script>
