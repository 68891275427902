<template>
  <div>
    <v-row justify="center">
      <v-col lg="2" class="s-col-form" cols="12">
        <s-date label="Fecha" v-model="item.HvsDate"></s-date
      ></v-col>
      <v-col lg="2" class="s-col-form" cols="12">
        <s-select-definition
          v-model="item.TypeService"
          :def="1171"
          label="Tipo Servicio"
        />
      </v-col>
      <v-col lg="2" class="s-col-form" cols="12">
        <s-select-definition
          v-model="item.TypeOrigin"
          :def="1174"
          label="Procedencia"
        />
      </v-col>
      <v-col lg="2" class="s-col-form" cols="12">
        <s-select-definition
          v-model="item.TypeDestination"
          :def="1175"
          label="Destino"
        />
      </v-col>
      <v-col
        class="s-col-form"
        cols="12"
        :lg="item.TypeProcessingPlant == 2 ? 2 : 4"
      >
        <s-select-definition
          v-model="item.TypeProcessingPlant"
          :def="1230"
          label="Planta Procesadora"
        />
      </v-col>
      <v-col
        cols="12"
        class="s-col-form"
        lg="2"
        v-if="item.TypeProcessingPlant == 2"
      >
        <s-select-definition
          label="Empacadora"
          :def="1226"
          v-model="item.TypeBaler"
        />
      </v-col>

    </v-row>
    <v-row justify="center">
      <v-col lg="2" cols="12" class="s-col-form">
        <s-search-lot-by-cod-senasa @searchSenasa="searchSenasa($event)" />
      </v-col>
      <v-col :lg="3" cols="12" class="s-col-form">
        <s-text readonly label="Productor" v-model="objLot.PrdText"></s-text>
      </v-col>
      <v-col lg="3" class="s-col-form" cols="12">
        <s-text readonly label="Fundo" v-model="objLot.FagName"></s-text>
      </v-col>
      <v-col lg="2" cols="12" class="s-col-form">
        <s-text readonly label="Lote" v-model="objLot.FltName"></s-text>
      </v-col>
      <v-col lg="2" cols="12" class="s-col-form">
        <s-text label="Cultivo" readonly v-model="objLot.TypeCultiveText" />
      </v-col>
    </v-row>
    <v-row>
      <v-col lg="2" class="s-col-form">
        <s-select-definition
          v-model="objLot.TypeCrop"
          :def="1172"
          label="Tipo Cultivo"
        />
      </v-col>
      <v-col class="s-col-form" lg="1">
        <s-text v-model="objLot.VrtDescription" readonly label="Variedad" />
      </v-col>
      <v-col lg="2" class="s-col-form" cols="12" sm="4" md="4">
        <s-select-definition
          label="Tipo Carga"
          :def="1231"
          v-model="item.TypeCharge"
          @input="changeTypeCharge()"
        />
      </v-col>
      <v-col lg="1" class="s-col-form" cols="12" sm="4" md="4"
        ><s-text label="Cantidad" number disabled v-model="item.HvsQuantity" />
      </v-col>
      <v-col lg="4" class="s-col-form" cols="12" sm="4" md="4">
        <s-text label="Direccion" disabled v-model="objLot.FagAddress" />
      </v-col>
      <v-col lg="2" class="s-col-form">
        <s-text label="Zona" v-model="objLot.ZonIDText" disabled />
      </v-col>
    </v-row>
    <v-row>
      <v-col lg="2" class="s-col-form">
        <s-text label="Sub Zona" v-model="objLot.SzoIDText" disabled />
      </v-col>
      <v-col lg="2" class="s-col-form" cols="12" sm="2" md="2">
        <s-select-supervice
          clearable
          label="Supervisor"
          v-model="NtpSuperviceID"
          full
        ></s-select-supervice>
        <!-- NtpSuperviceID -->
      </v-col>
      <v-col lg="4" class="s-col-form" cols="12" sm="4" md="4">
        <s-text label="Observación" v-model="item.Observation" />
      </v-col>
      <v-col lg="2" class="s-col-form" cols="12">
        <s-select-definition :def="1396" label="Tipo de Entrada de Mercancía" v-model="item.TypeEntryMerchandise">
        </s-select-definition>
      </v-col>
      <v-col lg="2" class="s-col-form" cols="12">
        <s-select-definition :def="1236" label="Estado" v-model="Status">
        </s-select-definition>
      </v-col>
    </v-row>

    <v-row
      ><v-col>
        <v-row v-if="objLot.FltID">
          <v-col cols="12">
            <alert-supplies :FltID="objLot.FltID"></alert-supplies>
          </v-col>

          <v-col cols="12" class="pt-5 pb-0">
            <table-foreman
              ref="crudForeman"
              :TypeCultive="objLot.TypeCultive"
              :isEdit="false"
              :harvestSchedule="item"
              v-model="itemsForeman"
              @dataItemsForeman="dataItemsForeman"
              :fltID="objLot.FltID"
            ></table-foreman>
          </v-col>
        </v-row>
        <v-row v-if="objLot.FltID">
          <v-col cols="12" class="mt-0">
            <table-carrier
              ref="crudCarrier"
              :Quantity="item.HvsQuantity"
              :harvestSchedule="item"
              @emitTableTransport="emitTableTransport($event)"
              v-model="dataTableTransport"
              @elementRemove="elementRemove($event)"
              @deleteItemTransport="deleteItemTransport($event)"
            ></table-carrier> </v-col
        ></v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SSearchLotByCodSenasa from "@/components/HarvestSchedule/sSearchLotByCodSenasa.vue";
import SSelectActivityArea from "@/components/FreshProduction/Turn/sSelectActivityArea.vue";
import SSelectVariety from "@/components/HarvestSchedule/SSelectVariety";
import SSelectAgricultural from "@/components/HarvestSchedule/SSelectAgricultural.vue";
import SSelectProducer from "@/components/HarvestSchedule/SSelectProducer";
import SSelectLot from "@/components/HarvestSchedule/SSelectLot.vue";
import SSelectSupervice from "@/components/HarvestSchedule/SSelectSupervice";
import _sHarvest from "@/services/Technicalassistance/HarvestSchedule";
import TableMaterial from "@/components/HarvestSchedule/TableMaterial.vue";
import TableCarrier from "@/components/HarvestSchedule/TableCarrier.vue";
import TableForeman from "@/components/HarvestSchedule/TableForeman.vue";
import _sGenParamService from "../../../services/General/ParameterService";
import _sAlertHistoryService from "@/services/FreshProduction/AlertHistoryService";
import AlertSupplies from "@/components/FreshProduction/FreshReception/AlertSupplies.vue";


export default {
  components: {
    SSelectActivityArea,
    SSelectAgricultural,
    SSelectVariety,
    SSelectProducer,
    SSearchLotByCodSenasa,
    SSelectLot,
    SSelectSupervice,
    TableForeman,
    TableCarrier,
    TableMaterial,
    AlertSupplies,
  },
  props: {
    objEdit: { type: Object },
  },

  data() {
    return {
      Status: 1,
      NtpSuperviceID: 0,
      typeCharge: 0,
      itemsForeman: [],
      TypeCropText: "",
      TypeCultiveText: "",
      VrtDescription: "",
      dataTableTransport: [],
      item: {},
      objLot: {},
      valParamNumVst: 0,
      xCount: 0,
      itemsDeleteTransport: [],
    };
  },

  watch: {
    objLot() {
      if (this.objLot !== undefined || this.objLot !== null) {
        if (this.objLot.FltID > 0) {
          if (this.objLot.VstCount < this.valParamNumVst) {
            this.$fun.alert(
              "Advertencia..! el Lote seleccionado, tiene menos de " +
                this.valParamNumVst +
                " visitas a campo. Visitas realizadas: " +
                this.objLot.VstCount,
              "warning"
            );

            let alh = {
              AlhMessage:
                "Lote: " +
                this.objLot.FltID +
                ", tiene menos de " +
                this.valParamNumVst +
                " visitas a campo. Visitas realizadas: " +
                this.objLot.VstCount,
              SecStatus: 1,
              AlhStatus: 1,
              FltID: this.objLot.FltID,
              AlhDateCreate: new Date(),
              SecCreate: new Date(),
              SecUpdate: new Date(),
              UsrCreateID: this.$fun.getUserID(),
              UsrUpdateID: this.$fun.getUserID(),
            };

            _sAlertHistoryService
              .save(alh, this.$fun.getUserID())
              .then((resp) => {
                if (resp.status == 200) {
                }
              });
          }
        }
      }
    },
  },
  methods: {
    inputSupervice(val) {
    },

    changeTypeCharge() {
      if (this.$refs.crudCarrier) {
        this.$refs.crudCarrier.clear(); 
      } 
    },

    loadParameter() {
      _sGenParamService
        .search(
          { PrmName: "CosechaNumeroVisitasPorLote" },
          this.$fun.getUserID()
        )
        .then((resp) => {
          if (resp.status == 200) {
            this.valParamNumVst = resp.data.PrmValue;
          }
        });
    },

    dataItemsForeman(dataItems) {
      this.itemsForeman = dataItems;
      this.item.HvsQuantity = 0;

      this.itemsForeman.forEach((element) => {
        this.item.HvsQuantity =
          parseInt(this.item.HvsQuantity) +
          parseInt(element.HvsQuantityMaterial);
      });
    },

    emitTableTransport(item) {
      this.dataTableTransport = item;
    },

    elementRemove(item) {
      this.xCount = 0;
    },

    deleteItemTransport(items) {
      this.itemsDeleteTransport = items;
    },

    save() {
      this.xCount = 0;

      if (this.Status == 2) {
        if (this.dataTableTransport.length == 0) {
          this.$fun.alert("Ingresar Transporte ", "warning");
          return;
        }
      }

      if (this.itemsForeman.length == 0) {
        this.$fun.alert("Ingresar Detalle", "warning");
        return;
      }
      if (this.objLot.FltID == 0 || this.objLot.FltID == null) {
        this.$fun.alert("Seleccione Lote", "warning");
        return;
      }
      if (this.NtpSuperviceID == 0 || this.NtpSuperviceID == null) {
        this.$fun.alert("Seleccione Supervisor", "warning");
        return;
      }
      this.item.UsrCreateID = this.$fun.getUserID();
      this.item.UsrUpdateID = this.$fun.getUserID();
      this.item.FltID = this.objLot.FltID;
      this.item.VrtID = this.objLot.VrtID;
      this.item.FagID = this.objLot.FagID;
      this.item.PrdID = this.objLot.PrdID;
      this.item.TypeCultive = this.objLot.TypeCultive;
      this.item.TypeCrop = this.objLot.TypeCrop;
      this.item.HvsStatus = this.Status;
      this.item.GenSuperviceID = this.NtpSuperviceID;
      this.item.DetailsGenForeman = this.itemsForeman;
      this.item.DetailsTransport = [
        ...this.dataTableTransport,
        ...this.itemsDeleteTransport,
      ];

      this.item.DetailsTransport.forEach((element) => {
        if (element.TypeLoad == 1) {
          this.xCount = this.xCount + 1;
        }
      });

      if (this.Status == 2) {
        if (this.item.DetailsTransport.length > 1) {
          this.$fun.alert(
            "Error, Solo se puede programar 01 Vehiculo para transporte de Fruta, Cualquier duda comuniquese con el Area de Producción",
            "warning"
          );
          return;
        }

        if (this.xCount > 1) {
          this.$fun.alert(
            "Error, Solo se puede programar 01 Vehiculo para transporte de Fruta, Cualquier duda comuniquese con el Area de Producción",
            "warning"
          );
          return;
        }

        if (this.xCount == 0) {
          this.$fun.alert(
            "Error... La Programacion de Cosecha no tiene ninguno vehiculo asignado para transporte de Fruta, porfavor verificar",
            "warning"
          );
          return;
        }
      }

      this.$fun.alert("Seguro de Guardar?", "question").then((re) => {
        if (re.value) debugger;
        _sHarvest.save(this.item, this.$fun.getUserID()).then((r) => {
          if (r.status == 200) {
            this.$fun.alert("Guardado Correctamente", "success");
            this.$emit("close");
          }
        });
      });
    },

    searchSenasa(item) {
      if (item) {
        this.objLot = item;
        this.NtpSuperviceID = item.GenSuperviceID;

        this.$nextTick().then(() => {
        });
      } else this.objLot = {};
    },
  },
  created() {
    this.loadParameter();
    if (this.objEdit != null) {
      this.item = Object.assign(this.objEdit, {});
      this.Status = this.objEdit.HvsStatus;
      this.NtpSuperviceID = this.item.GenSuperviceID;
    } else this.item = {};

    if (this.item.HvsID > 0) {
      this.objLot = {
        FltID: this.item.FltID,
        FltName: this.item.FltName,
        FagName: this.item.FagName,
        PrdText: this.item.PrdText,
        VrtDescription: this.item.VrtDescription,
        TypeCropText: this.item.TypeCropText,
        TypeCrop: this.item.TypeCrop,
        TypeCultive: this.item.TypeCultive,
        VrtID: this.item.VrtID,
        PrdID: this.item.PrdID,
        TypeCultiveText: this.item.TypeCultiveText,
        TypeCultive: this.item.TypeCultive,
        FagAddress: this.item.FagAddress,
      };
      //this.NtpSuperviceID = this.item.FagResponsibleSupervisor;
      this.NtpSuperviceID = this.item.GenSuperviceID;
    }
  },
};
</script>
