import Service from "../Service";
const resource = "HarvestScheduleGuide/";

export default {
    fruitreferralguide(HvsID, requestID) {
        return Service.post(resource + "fruitreferralguide", {}, {
            params: { HvsID: HvsID, requestID: requestID },
        });
    },

    materialreferralguide(HvsID, requestID) {
        return Service.post(resource + "materialreferralguide", {}, {
            params: { HvsID: HvsID, requestID: requestID },
        });
    },
};