import _sColor from '@/services/FreshProduction/PrfColorService';
<template>
	<div>
		<v-card>
			<s-select-definition
				:def="1403"
				@getDataInitial="getDataInitialReasonTransfer($event)"
				hidden
			></s-select-definition>

			<v-container>
				<v-row>
					<v-col>
						<v-card
							dense
						>
							<v-container class="text-center">
								<v-row align="center">
									<v-col class="s-col-form">
										<h3><b>R.U.C. N° 20329725431</b></h3>
									</v-col>
								</v-row>
								<v-row align="center">
									<v-col class="s-col-form">
										<h3><b>GUÍA DE REMISIÓN - REMITENTE</b></h3>
									</v-col>
								</v-row>
								<v-row align="center">
									<v-col class="s-col-form">
										<h3><b>030 - N° 0002554</b></h3>
									</v-col>
								</v-row>
							</v-container>
						</v-card>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="10">
						<v-card dense>
							<v-container>
								<v-row>
									<v-col class="s-col-form">
										<v-label>
											Nombre o Razón Social: 
										</v-label>
										{{dataGuide.BusinessName}}
									</v-col>
								</v-row>
								<v-row>
									<v-col class="s-col-form">
										<v-label>
											Dirección Pto Llegada: 
										</v-label>
										{{dataGuide.ArrivalPoint}}
									</v-col>
								</v-row>
								<v-row>
									<v-col class="s-col-form">
										<v-label>
											RUC: 
										</v-label>
										{{dataGuide.Ruc}}
									</v-col>
								</v-row>
							</v-container>
						</v-card>
					</v-col>
					<v-col cols="2">
						<v-card dense class="pb-80">
							<v-container>
								<v-row>
									<v-col>
										<v-label>
											FECHA DE EMISIÓN: 
										</v-label>
										<v-row>
											<v-col  class="s-col-form">
												DIA
											</v-col>
											<v-col  class="s-col-form">
												MES
											</v-col>
											<v-col  class="s-col-form">
												AÑO
											</v-col>
										</v-row>
										<v-row>
											<v-col  class="s-col-form">
												{{dataGuide.DayIssue}}
											</v-col>
											<v-col  class="s-col-form">
												{{dataGuide.MonthIssue}}
											</v-col>
											<v-col  class="s-col-form">
												{{dataGuide.YearIssue}}
											</v-col>
										</v-row>
									</v-col>
								</v-row>
							</v-container>
						</v-card>
					</v-col>
				</v-row>
				<v-row>
					<v-col>
						<v-card dense>
							<v-container>
								<v-row>
									<v-col class="s-col-form">
										<v-label>
											Fecha Inicio del traslado
										</v-label>
										{{dataGuide.TransferDate}}
									</v-col>
									<v-col class="s-col-form">
										<v-label>
											Tipo y N° Doc de Comprob. de Pago:
										</v-label>
										{{""}}
									</v-col>
								</v-row>
								<v-row>
									<v-col class="s-col-form">
										<v-label>
											Dirección del punto de partida
										</v-label>
										{{dataGuide.FagAddress}}
									</v-col>
								</v-row>
							</v-container>
						</v-card>
					</v-col>
				</v-row>
				<v-row>
					<v-col>
						<v-card>
							<v-container  class="">
								<v-row>
									<v-col cols="2" class="s-col-form">
										<h3><b>CANTIDAD</b></h3>
									</v-col>
									<v-col cols="8" class="s-col-form">
										<h3><b>DESCRIPCIÓN</b></h3>
									</v-col>
									<v-col cols="2" class="s-col-form">
										<h3><b>TOTAL</b></h3>
									</v-col>
								</v-row>
								<v-row v-for="(item, index) in dataGuide.details" :key="index">
									<v-col cols="2" class="s-col-form">
										{{item.MtrQuantity}}
									</v-col>
									<v-col cols="8" class="s-col-form">
										{{item.MtrMaterial}}
									</v-col>
									<v-col cols="2" class="s-col-form">
										{{""}}
									</v-col>
								</v-row>
								
							</v-container>
						</v-card>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="7">
						<v-row>
							<v-col>
								<v-card>
									<v-container>
										<v-row>
											<v-col class="s-col-form">
												<h4><b>Datos Unidad Transporte / Conductor</b></h4>
											</v-col>
										</v-row>
										<v-row>
											<v-col class="s-col-form">
												<v-label>
													Vehículo Marca y Placa N°
												</v-label>
											</v-col>
											<v-col class="s-col-form">
												{{dataGuide.BrandVehicle + " " + dataGuide.PlateVehicle}}
											</v-col>
										</v-row>
										<v-row>
											<v-col class="s-col-form">
												<v-label>
													Constancia de Inscripción N°
												</v-label>
											</v-col>
											<v-col class="s-col-form">
												{{"9867857657"}}
											</v-col>
										</v-row>
										<v-row>
											<v-col class="s-col-form">
												<v-label>
													Chofer
												</v-label>
											</v-col>
											<v-col class="s-col-form">
												{{dataGuide.DriverVehicle}}
											</v-col>
										</v-row>
										<v-row>
											<v-col class="s-col-form">
												<v-label>
													Licencia
												</v-label>
											</v-col>
											<v-col class="s-col-form">
												{{dataGuide.DriverLicense}}
											</v-col>
										</v-row>
									</v-container>
								</v-card>
							</v-col>
						</v-row>
						<v-row>
							<v-col>
								<v-card>
									<v-container>
										<v-row>
											<v-col class="s-col-form">
												<h4><b>DATOS DEL TRANSPORTISTA</b></h4>
											</v-col>
										</v-row>
										<v-row>
											<v-col class="s-col-form">
												<v-label>
													Nombre o Razón Social
												</v-label>
											</v-col>
											<v-col class="s-col-form">
												{{dataGuide.TransportBusinessName}}
											</v-col>
										</v-row>
										<v-row>
											<v-col class="s-col-form">
												<v-label>
													R.U.C. N°
												</v-label>
											</v-col>
											<v-col class="s-col-form">
												{{dataGuide.RucTransport}}
											</v-col>
										</v-row>
									</v-container>
								</v-card>
							</v-col>
						</v-row>
						<v-row>
							<v-col>
								<v-card>
									<v-container>
										<v-row>
											<v-col class="s-col-form">
												<v-label>
													Costo Mínimo de Traslado
												</v-label>
											</v-col>
											<v-col class="s-col-form">
												{{""}}
											</v-col>
										</v-row>
									</v-container>
								</v-card>
							</v-col>
						</v-row>
					</v-col>
					<v-col cols="5">
						<v-row>
							<v-col>
								<v-card>
									<v-container>
										<v-row>
											<v-col>
												<h4><b>MOTIVO DEL TRASLADO</b></h4>
											</v-col>
										</v-row>
										<v-row>
											<v-col cols="6" class="s-col-form" v-for="(item, index) in reasonTransfer" :key="index">
												<v-checkbox
													readonly
													:label="item.DedDescription"
													color="red"
													:value="item.DedValue"
													v-model="dataGuide.ReasonTransferSelected"
													hide-details=""
													style="margin-top : -14px"
												></v-checkbox>
											</v-col>
										</v-row>
										<v-row v-if="dataGuide.ReasonTransferSelected == 13">
											<v-col>
												<s-textarea
													label="Motivo"
													v-model="dataGuide.ReasonTransfer"
													readonly
												></s-textarea>
											</v-col>
										</v-row>
									</v-container>
								</v-card>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
			</v-container>
		</v-card>
	</div>
</template>

<script>
	import _sGuide from "@/services/Technicalassistance/HarvestScheduleGuideService";

	export default {
		props: {
			HvsID: {
				type: Number,
				default: 0
			},
		},

		data() {
			return {
				dataGuide: {},
				reasonColumn1 : [],
				reasonColumn2 : [],
				reasonTransfer : [],
			}
		},

		watch: {
			HvsID() {
				this.getDataReferralGuide();
			}
		},

		methods: {
			getDataInitialReasonTransfer(items)
			{
				var countReason = items.length;
				this.reasonTransfer = items;
				if(countReason > 0)
				{
					items.forEach((element, index) => {
						let count = (index + 1)
						if(count%2 == 0)
						{
							this.reasonColumn1.push(element)
						}
						else
						{
							this.reasonColumn2.push(element)
						}
					});
				}
			},

			getDataReferralGuide() {
				_sGuide
				.materialreferralguide(this.HvsID, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200)
					{
						this.dataGuide = resp.data;
						console.log('dataaa ', resp.data);
					}
				})
			}
		},

		created () {
			this.getDataReferralGuide();
		},
	}
</script>

