<template>
  <div>
    <v-card class="elevation-0 mt-0 pt-0">
      <v-row>
        <v-col class="pb-0 pt-0">
          <h2>Capataces</h2>
        </v-col>
        <v-spacer></v-spacer>
        <v-btn
          class="mr-3 elevation-5"
          x-small
          fab
          color="warning"
          @click="clearForemanEdit"
          ><i class="fas fa-broom"></i
        ></v-btn>
        <v-btn
          class="mr-3 elevation-5"
          x-small
          fab
          color="primary"
          @click="addCapataz"
          ><i class="fas fa-plus"></i
        ></v-btn>
      </v-row>
      <v-col class="pl-0 pr-0"><v-divider></v-divider> </v-col>
      <v-row>
        <v-col lg="6" class="pb-2">
          <v-row>
            <v-col lg="6" class="s-col-form">
              <s-select-lot
                v-if="fltID"
                :fkFltID="fltID"
                :PrdID="PrdID"
                :text="FltName"
                label="Sub Lote"
                clearable
                return-object
                v-model="ObjFarmLot"
                @input="inputFarmSubLot($event)"
                full
              />
            </v-col>
            <v-col lg="6" class="s-col-form">
              <s-select-foreman
                clearable
                label="Capataz"
                full
                return-object
                v-model="ObjForeman"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col lg="6" class="s-col-form">
              <s-text
                ref="harvest"
                number
                label="#Cosechadores"
                numbe:ripple="false"
                v-model="NumberHarvesters"
              />
            </v-col>
            <v-col lg="6" class="s-col-form">
              <s-text
                ref="jabas"
                label="#Jabas/Otros"
                number
                v-model="NumberJabas"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col lg="3" class="s-col-form">
              <s-select-farm
                @dblclick="dblclickTraspot($event)"
                :typeCharge="harvestSchedule.TypeCharge"
                Quantity="0"
              />
            </v-col>
            <v-col lg="7" class="s-col-form" v-if="objTransport">
              <s-text
                label="Conductor"
                :value="objTransport.VehPlate + ' ' + objTransport.Driver"
              />
            </v-col>
            <!-- <span><v-btn x-small fab elevation="0" class="mt-4" color="error" @click="deleteTransCap()">X</v-btn></span> -->
          </v-row>
        </v-col>
        <v-col class="pb-2 pt-0" lg="6">
          <v-data-table
            dense
            :headers="headers1"
            hide-default-footer
            :items="itemsMaterial"
            item-key="ArcID"
            class="elevation-0 mt-5"
          >
            <template v-slot:item.MtrQuantity="props">
              <s-text decimal v-model="props.item.MtrQuantity"></s-text>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="">
          <template>
            <v-data-table
              v-model="selectedForeman"
              :headers="headerForeman"
              dense
              :items="itemsForeman"
              hide-default-footer
              item-key="Line"
              label="Edit"
              singleSelect
              show-select
              class="elevation-0"
              @item-selected="dblclick($event)"
              
            >
              <template v-slot:item.Actions="{ item }">
                <v-btn block color="error" @click="removeCapataz(item)" x-small>
                  <v-icon>mdi-close</v-icon></v-btn
                >
              </template>
            </v-data-table>
          </template>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import SSelectFarm from "@/components/HarvestSchedule/SSelectFarm";
import _sFarm from "@/services/Technicalassistance/AgriculturalFarmsService";
import SSelectProducer from "@/components/HarvestSchedule/SSelectProducer";
import SSelectSupervice from "@/components/HarvestSchedule/SSelectSupervice";
import SSelectForeman from "@/components/HarvestSchedule/SSelectForeman";
import SSearchLotByCodSenasa from "./sSearchLotByCodSenasa.vue";
import SSelectLot from "./SSelectLot.vue";
import _HSServiceMaterial from "@/services/Technicalassistance/MaintainerCropArticleSap/MaintainerCropArticleSapService";
import _sHarvest from "@/services/Technicalassistance/HarvestSchedule";
import _sGenParamService 		from "@/services/General/ParameterService";

export default {
  components: {
    SSelectProducer,
    SSelectSupervice,
    SSelectForeman,
    SSearchLotByCodSenasa,
    SSelectLot,
    SSelectFarm,
  },
  props: {
    harvestSchedule: {
      type: Object,
      default: [],
    },
    fltID: {
      type: Number,
      default: 0,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    forSearch: {
      type: Boolean,
      default: false,
    },
    FagIDg: {
      type: Number,
      default: 0,
    },
    TypeCultive: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      foremanOld: {},
      objTransport: null,
      itemsDeleteForeman: [],
      itemsDeleteTransport: [],
      editForeman: false,
      sameFagIDg: true,
      min: 0,
      SendDataMaterial: false,
      GenForemanID: 0,
      CodSenasa: "",
      searchSenasaActive: false,
      ObjProducer: {},
      ObjFarmLot: [],
      ObjSupervice: [],
      ObjForeman: [],
      itemsForeman: [],
      selectedForeman: [],
      dataByCodSenasa: [],
      dataTableListMaterial: [],
      NumberHarvesters: 0,
      NumberJabas: 0,
      PrdID: 0,
      PrdText: "",
      FltName: "",
      FltID: 1097,
      HvsID: 0,
      FagID: 0,
      TypeCropText: "",
      TypeCultiveText: "",
      VrtDescription: "",

      headerForeman: [
        {
          text: "Capataz",
          value: "Foreman",
          width: 300,
          sortable: false,
        },
        { text: "Lote", value: "FltName", sortable: false },
        {
          text: "N°Cosechadores",
          value: "HvsQuantityPerson",
          width: 120,
          sortable: false,
        },
        {
          text: "N°Jabas",
          value: "HvsQuantityMaterial",
          width: 120,
          sortable: false,
        },
        {
          text: "Vehículo",
          value: "VehPlate",
          width: 120,
          sortable: false,
        },
        {
          text: "Conductor",
          value: "DriverName",

          sortable: false,
        },
        {
          text: "Acciones",
          value: "Actions",
          width: 100,
          sortable: false,
        },
      ],

      selectedMaterial: [],
      headers1: [
        {
          text: "Nombre de Material",
          value: "MtrMaterial",
          sortable: false,
        },
        {
          text: "Cantidad",
          value: "MtrQuantity",
          width: 80,
          sortable: false,
        },
      ],
      itemsMaterial: [],
      validaCapataz: 1
    };
  },

  watch: {
    FagIDg() {
      this.FagID = this.FagIDg;
    },

    harvestSchedule() {
      this.HvsID = this.harvestSchedule.HvsID;
    },

    "harvestSchedule.FagAddress"() {
      this.FagID = this.harvestSchedule.FagID;
    },

    ObjProducer() {
      if (this.ObjProducer) {
        this.PrdID = this.ObjProducer.PrdID;
      }
    },

    isEdit() {
      if (this.isEdit) {
        this.harvestSchedule.HvsID = 0;
      }
    },

    TypeCultive() {
      if (this.TypeCultive) {
        _HSServiceMaterial
          .listMaterial(this.TypeCultive, this.$fun.getUserID())
          .then((response) => {
            let dataM = response.data;
            this.itemsMaterial = [];
            dataM.forEach((element) => {
              let obj = {
                ArcID: element.ArtID,
                MtrMaterial: element.MtrMaterial,
                MtrQuantity: 0,
                UsrCreateID: this.$fun.getUserID(),
                UsrUpdateID: this.$fun.getUserID(),
              };
              this.itemsMaterial.push(obj);
            });
            this.$emit("emitDataTableMaterial", this.itemsMaterial);
          });
      }
    },
  },

  methods: {
    /* deleteTransCap(){
				this.objTransport = null
			}, */

    dblclickTraspot({ item }) {
      this.objTransport = item;
    },
    clear() {
      this.itemsForeman = [];
    },
    inputFarmSubLot(item) {},
    emitDataTableMaterial(item) {
      this.dataTableListMaterial = item;
    },

    validateDataTableMaterial(itemsMaterial) {
      let hasZero = false;
      itemsMaterial.forEach((element) => {
        if (element.MtrQuantity == 0) {
          hasZero = true;
        }
      });
      return hasZero;
    },

    addCapataz() {

      let validate = false; 
      if (validate) {
        this.$fun.alert("Llenar Cantidad de Materiales", "warning");
        return;
      } else {

        if (this.ObjFarmLot == null || this.ObjFarmLot.FltID == null) {
          this.$fun.alert("Selecione Lote!", "warning");
          return;
        }
        
        if(this.validaCapataz == 1){
           if (this.ObjForeman == null || this.ObjForeman.NtpID == null) {
            this.$fun.alert("Selecione Capataz!", "warning");
            return;
          }
        }
       
        let objC = {
          NtpIDDriver: this.objTransport ? this.objTransport.NtpIDDriver : null,
          DriverName: this.objTransport ? this.objTransport.Driver : '',
          VehPlate: this.objTransport ? this.objTransport.VehPlate : '',
          FagID: this.FagIDg,
          HvsID: this.HvsID,
          PrdID: this.ObjProducer.PrdID,
          PrdText: this.ObjProducer.PrdCardName,
          FltID: this.ObjFarmLot.FltID,
          FltName: this.ObjFarmLot.FltName,
          GenForemanID: this.ObjForeman.NtpID,
          Foreman: this.ObjForeman.Foreman,
          HvsQuantityPerson: this.NumberHarvesters,
          HvsQuantityMaterial: this.NumberJabas,
          DetailsMaterial: _.cloneDeep(this.itemsMaterial),

          UsrCreateID: this.$fun.getUserID(),
          UsrUpdateID: this.$fun.getUserID(),
        };

        if (this.HvsID > 0) {
          objC.HvsID = this.HvsID;
        }
        let exist = false;
        if (!this.editForeman)
          this.itemsForeman.forEach((element) => {
            if (this.ObjForeman.NtpID == element.GenForemanID) {
              exist = true;
              this.$fun.alert("Capataz Repetitivo", "warning");
              return;
            }
          });
        if (this.NumberHarvesters == 0 || this.NumberHarvesters == null) {
          this.$refs.harvest.error("Ingrese Cosechadores");
          return;
        } else if (this.NumberJabas == 0 || this.NumberJabas == null) {
          this.$refs.jabas.error("Ingrese Número de Jabas");
          return;
        } else {
          var suma = 0;
          if (this.editForeman) {
            
            let foreman = this.itemsForeman.find(
              (x) => (x.GenForemanID == this.foremanOld.GenForemanID)
            );
            foreman.FagID = this.FagIDg;
            foreman.HvsID = this.HvsID;
            (foreman.NtpIDDriver = this.objTransport.NtpIDDriver ),
              (foreman.DriverName = this.objTransport.Driver ),
              (foreman.VehPlate = this.objTransport.VehPlate ),
              (foreman.PrdID = this.ObjProducer.PrdID);
            foreman.FltID = this.ObjFarmLot.FltID;
            foreman.GenForemanID = this.ObjForeman.NtpID;
            foreman.Foreman = this.ObjForeman.Foreman;
            foreman.HvsQuantityPerson = this.NumberHarvesters;
            foreman.HvsQuantityMaterial = this.NumberJabas;
            foreman.DetailsMaterial = _.cloneDeep(this.itemsMaterial);
          } else {
            if(!exist){
              this.itemsForeman.push(objC);
              this.orderCapataz();
            }
            
          }

          this.clearForemanEdit();
        }

        this.ObjCapataz = [];
        this.$emit("dataItemsForeman", this.itemsForeman);

        this.editForeman = false;
      }
    },
    clearForemanEdit() {

      this.objTransport = null;
      this.NumberJabas = 0;
      this.NumberHarvesters = 0;
      this.ObjFarmLot = {};
      this.ObjForeman = null;
      this.itemsMaterial.map((x) => {
        x.MtrQuantity = 0;
      });
      this.selectedForeman = [];
    },

    orderCapataz(){
      let i = 0;
      this.itemsForeman.map((e)=> {
        i++;
        e.Line = i;
      });
    },
    
    removeCapataz(element) {
      if(this.itemsForeman.length > 0) {
        if(element.GfrID > 0){

          element.SecStatus = 0;
          this.itemsDeleteForeman.push(
            // this.itemsForeman.filter(x=>x.Line == element.Line)[0],
            structuredClone(element)
          );

          // this.itemsDeleteForeman.forEach((x) => {
          //   x.SecStatus=0;
          // });
        }

        this.itemsForeman = this.itemsForeman.filter(
          x => x.GenForemanID != element.GenForemanID
        );

        this.orderCapataz();
      }


    //   this.selectedForeman = [];
    //  debugger;
    //   this.ObjForeman = {};
    //   this.itemsForeman = this.itemsForeman.filter(
    //     (x) => x.GenForemanID != element.GenForemanID
    //   );
    //   element.SecStatus = 0;

    //   if (element.GfrID > 0) {
    //     this.itemsDeleteForeman.push(element);
    //     this.$emit("deleteItemForeman", this.itemsDeleteForeman);
    //   }
    //   this.$emit("dataItemsForeman", this.itemsForeman);
    },


    //este metodo sirve para el evento
    dblclick({ item, value }) {
      this.foremanOld = {GenForemanID: item.GenForemanID, NtpID: item.NtpID}
      if (value) {
        this.editForeman = true;
        this.GenForemanID = item.GenForemanID;
        this.ObjForeman = { NtpID: item.GenForemanID, Foreman: item.Foreman };
        this.ObjFarmLot = { FltID: item.FltID, FltName: item.FltName };
        this.objTransport = {
          NtpIDID: item.NtpIDDriver,
          Driver: item.DriverName,
          VehPlate: item.VehPlate,
        };
        this.itemsMaterial.map((e) => {
          let findItem = item.DetailsMaterial.find((x) => e.ArcID == x.ArcID);
          if (findItem) e.MtrQuantity = findItem.MtrQuantity;
        });
        this.PrdText = item.PrdText;
        this.FltName = item.FltName;
        this.NumberJabas = item.HvsQuantityMaterial;
        this.NumberHarvesters = item.HvsQuantityPerson;

      } else {
        this.clearForemanEdit();
      }
    },
  },

  created() {

      _sGenParamService
					.search(
						{ PrmName: "cosechavalidacapataz" },
						this.$fun.getUserID(),
					)
					.then(resp => {
						this.validaCapataz = resp.data.PrmValue;
					});

    if (this.TypeCultive) {
      _HSServiceMaterial
        .listMaterial(this.TypeCultive, this.$fun.getUserID())
        .then((response) => {
          let dataM = response.data;
          this.itemsMaterial = [];
          dataM.forEach((element) => {
            let obj = {
              ArcID: element.ArtID,
              MtrMaterial: element.MtrMaterial,
              MtrQuantity: 0,
              UsrCreateID: this.$fun.getUserID(),
              UsrUpdateID: this.$fun.getUserID(),
            };
            this.itemsMaterial.push(obj);
          });
          this.$emit("emitDataTableMaterial", this.itemsMaterial);
        });
    }
    this.itemsForeman = [];
    if (this.harvestSchedule.HvsID != null && this.harvestSchedule.HvsID != 0) {
      _sHarvest
        .listGenForemanS(this.harvestSchedule.HvsID)
        .then((response) => {
          let datap = response.data;
          datap.forEach((element) => {
            var arrayDataInitialMaterial = [];
            _sHarvest.listMaterial(element.GfrID).then((response) => {
              let data = response.data;
              let objMaterial;
              data.forEach((element) => {
                objMaterial = {
                  GfrID: element.GfrID,
                  ArcID: element.ArcID,
                  MtrMaterial: element.MtrMaterial,
                  MtrQuantity: element.MtrQuantity,
                  LgsUnt: element.LgsUnt,
                  MtrId: element.MtrId,
                  SecStatus: 1,
                };
                arrayDataInitialMaterial.push(objMaterial);
              });
            });

            let obj = {
              GfrID: element.GfrID,
              HvsQuantityPerson: element.HvsQuantityPerson,
              HvsQuantityMaterial: element.HvsQuantityMaterial,
              GenForemanID: element.GenForemanID,
              Foreman: element.Worker,
              HvsID: element.HvsID,
              FltName: element.FltName,
              PrdText: element.PrdText,
              NtpIDDriver: element.NtpIDDriver,
              DriverName: element.DriverName,
              VehPlate: element.VehPlate,
              PrdID: element.PrdID,
              FltID: element.FltID,
              TypeCrop: element.TypeCrop,
              TypeCultive: element.TypeCultive,
              DetailsMaterial: arrayDataInitialMaterial,
              SecStatus: 1,
            };
            this.itemsForeman.push(obj);
            this.orderCapataz();
            this.$emit("dataItemsForeman", this.itemsForeman);
          });
        })
        .catch((err) => {});
    }
  },
};
</script>
