<template>
	<div>
		<v-card>
			<v-row>
				<v-col class="pb-0 pt-0">
					<s-toolbar
						remove
						:elevation="2"
						@removed="removeMaterial()"
						label="Materiales"
					></s-toolbar>
				</v-col>
			</v-row>
			<v-row>
				<v-col class="pt-0">
					<v-data-table
						dense
						v-model="selectedMaterial"
						:headers="headers1"
						:items="itemsMaterial"
						item-key="ArcID"
						show-select
						class="elevation-0"
					>
						<template v-slot:item.MtrQuantity="props">
							<s-text number v-model="props.item.MtrQuantity" @input="input"></s-text>
						</template>
					</v-data-table>
				</v-col>
			</v-row>
		</v-card>
	</div>
</template>

<script>
import _HSServiceMaterial from "@/services/Technicalassistance/MaintainerCropArticleSap/MaintainerCropArticleSapService";
export default {
	props: {
		/* harvestSchedule: {
			type: Object
		}, */
		SendDataMaterial: {
			type: Boolean,
			default: false,
		},
		TypeCultive: {
			type:  Number,
			default: 0
		},
		validateMaterial: {
			type: Boolean,
			default: false
		},
	},
	data() {
		return {
			selectedMaterial: [],
			headers1: [
				{ text: "ArticuloID", value: "ArcID", width: 80 },
				{ text: "Nombre", value: "MtrMaterial" },
				{ text: "Cantidad", value: "MtrQuantity", width: 80 },
			],
			itemsMaterial: [],
		}
	},


	methods: {
		loadDataInitial() {
			if(this.TypeCultive){
				_HSServiceMaterial.listMaterial(this.TypeCultive, this.$fun.getUserID()).then((response) => {
					let dataM = response.data;
					this.itemsMaterial = [];
					dataM.forEach((element) => {
					let obj = {
						ArcID: element.ArtID,
						MtrMaterial: element.MtrMaterial,
						MtrQuantity:0
					};
					this.itemsMaterial.push(obj);
					});
					this.$emit('emitDataTableMaterial', this.itemsMaterial);
				});
			}
		},

		removeMaterial() {
			this.selectedMaterial.forEach((element) => {
				this.itemsMaterial = this.itemsMaterial.filter(
				(x) => x.ArcID != element.ArcID
				);
			});
		},

		validateMaterialMethod()
		{
			var hasZero = false;
			this.itemsMaterial.forEach((element) => {
				if(element.MtrQuantity == 0){
					hasZero = true;
				}
			});
			if(hasZero){
				this.$fun.alert("Llenar Cantidad de Materiales","warning")
			}
		},

		input(){
			this.$emit("inputTableListMaterial", this.itemsMaterial)
		}
	},

	watch: {
		TypeCultive() {
			this.loadDataInitial();
		},
		validateMaterial()
		{
			this.validateMaterialMethod();
		},
	},
};
</script>